// MusicNote.js
import React from 'react';
import avatar from './avatar.webp'; // 导入 avatar 图片

const MusicNote = () => {
    return (
        <div className="content">
            <div className="dots">
                <div className="dot"><span></span></div>
                <div className="dot"><span></span></div>
                <div className="dot"><span></span></div>
                <div className="dot"><span></span></div>
                <div className="dot"><span></span></div>
                <div className="dot"><span></span></div>
                <div className="dot"><span></span></div>
                <div className="dot"><span></span></div>
                <div className="dot"><span></span></div>
                <div className="dot"><span></span></div>
                <div className="dot"><span></span></div>
                <div className="dot"><span></span></div>
                <div className="ring" style={{
                    backgroundImage: `url(${avatar})`,
                    position: 'relative',
                    zIndex: 100,
                }}></div>
            </div>
        </div>
    );
};

export default MusicNote;
